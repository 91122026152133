<script>
import VueApexCharts from 'vue-apexcharts';
import { formatNumber } from '@/util/helper';
export default {
    name: 'AppHomeCardWithChart',
    methods: { formatNumber },
    components: {
        apexchart: VueApexCharts
    },
    props: {
        chartLineColors: {
            type: Array,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        iconSize: {
            type: String,
            required: true
        },
        iconColor: {
            type: String,
            required: true
        },
        totalText: {
            type: String,
            required: true
        },
        total: {
            type: [Number, String],
            required: true
        },
        series: {
            type: Array,
            required: true
        },
        dynamicXaxis: {
            type: Array,
            required: true
        },
        loaded: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            chartOptions: {
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                chart: {
                    toolbar: {
                        show: false
                    },
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: [],
                stroke: {
                    curve: 'smooth',
                    width: 2.5,
                    colors: []
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 0.9,
                        opacityFrom: 0.5,
                        opacityTo: 0.5,
                        stops: [0, 80, 100]
                    },
                    colors: []
                },
                xaxis: {
                    categories: []
                },
                yaxis: [
                    {
                        y: 0,
                        offsetX: 0,
                        offsetY: 0,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    }
                ],
                tooltip: {
                    x: { show: true },
                    y: {
                        formatter: (seriesName) => `${formatNumber(seriesName)}`
                    }
                },
                markers: {
                    colors: []
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 130
                            }
                        }
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 100
                            }
                        }
                    }
                ]
            }
        };
    },
    created() {
        this.chartOptions.xaxis.categories = this.dynamicXaxis;
        this.chartOptions.markers.colors = this.chartLineColors;
        this.chartOptions.stroke.colors = this.chartLineColors;
        this.chartOptions.fill.colors = this.chartLineColors;
        this.chartOptions.colors = this.chartLineColors;
    }
};
</script>

<template>
    <div class="d-flex flex-column justify-content-between h-100">
        <div class="home-card__top p-1 d-flex align-items-center mb-1">
            <b-avatar :variant="iconColor" size="lg">
                <feather-icon :icon="icon" :size="iconSize" />
            </b-avatar>
            <div class="ml-1">
                <span>{{ $t(`${totalText}`) }}</span>
                <p class="font-large-1 font-weight-bold mb-0">
                    {{ formatNumber(total) }}
                </p>
            </div>
        </div>
        <div class="home-card__bottom">
            <apexchart
                height="300"
                type="area"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
