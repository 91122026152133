<script>
import AppHomeCardWithChart from '@/components/AppHomeCardWithChart.vue';
import api from '@/services/api';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { MERCHANT_ID } from '@/constants/names';
import VueApexCharts from 'vue-apexcharts';
import { formatNumber } from '@/util/helper';
import { statisticsFormatDate } from '@/util/date/calendar.util';
import { BButton } from 'bootstrap-vue';
import FlatPickr from 'vue-flatpickr-component';
import { checkLocales } from '@/util/lang';

export default {
    name: 'AppMatchingStatistics',
    components: {
        BButton,
        ProgressLinear,
        AppHomeCardWithChart,
        apexchart: VueApexCharts,
        FlatPickr
    },
    data() {
        return {
            uniqueDatesSet: new Set(),
            gifts: {
                series: [
                    {
                        name: this.$t('titles.gifts'),
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            giftsMatching: {
                series: [
                    {
                        name: this.$t('titles.gifts'),
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            invoice: {
                series: [
                    {
                        name: 'Invoice',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            invoiceMatching: {
                series: [
                    {
                        name: 'Invoice',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitTotal: {
                series: [
                    {
                        name: 'Profit Total',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitTotalMatching: {
                series: [
                    {
                        name: 'Profit Total',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitExpenses: {
                series: [
                    {
                        name: 'Profit Expenses',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitExpensesMatching: {
                series: [
                    {
                        name: 'Profit Expenses',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitRevenue: {
                series: [
                    {
                        name: 'Profit Revenue',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            profitRevenueMatching: {
                series: [
                    {
                        name: 'Profit Revenue',
                        data: []
                    }
                ],
                xaxis: [],
                completed: false,
                chartLineColors: ['#7367f0'],
                total: null
            },
            verificationPieChart: {
                verified: null,
                unverified: null,
                completed: false,
                series: [],
                colors: [
                    '#7367f0',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                labels: [],
                responsive: [
                    {
                        breakpoint: 576,
                        options: {
                            chart: {
                                width: 320
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                chart: {
                    width: 100,
                    type: 'donut',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            verificationPieChartMatching: {
                verified: null,
                unverified: null,
                completed: false,
                series: [],
                colors: [
                    '#7367f0',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                labels: [],
                responsive: [
                    {
                        breakpoint: 576,
                        options: {
                            chart: {
                                width: 320
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                chart: {
                    width: 100,
                    type: 'donut',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            activeUsersPieChart: {
                active: null,
                inactive: null,
                completed: false,
                series: [],
                colors: [
                    '#7367f0',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                labels: [],
                responsive: [
                    {
                        breakpoint: 576,
                        options: {
                            chart: {
                                width: 320
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                chart: {
                    width: 100,
                    type: 'donut',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            activeUsersPieChartMatching: {
                active: null,
                inactive: null,
                completed: false,
                series: [],
                colors: [
                    '#7367f0',
                    '#a7204c',
                    '#f87002',
                    '#e17dab',
                    '#0097df',
                    '#739373',
                    '#b03966',
                    '#057f5f',
                    '#002296'
                ],
                labels: [],
                responsive: [
                    {
                        breakpoint: 576,
                        options: {
                            chart: {
                                width: 320
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                chart: {
                    width: 100,
                    type: 'donut',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                }
            },
            date_start: null,
            date_end: null,
            first_day: null,
            matchingDateStart: null,
            matchingDateEnd: null,
            matchingFirstDay: null,
            showNoDataFrom: true,
            showNoDataTo: true,
            fromDatesText: {
                from: '',
                to: ''
            },
            toDatesText: {
                from: '',
                to: ''
            }
        };
    },

    computed: {
        MERCHANT_ID() {
            return MERCHANT_ID;
        },
        subscriber() {
            if (parseInt(MERCHANT_ID) === 1) {
                return {
                    series: [
                        {
                            name: 'APP',
                            data: []
                        },
                        {
                            name: 'TELEGRAM',
                            data: []
                        },
                        {
                            name: 'USSD',
                            data: []
                        }
                    ],
                    xaxis: [],
                    completed: false,
                    chartLineColors: ['#00e296', '#2AABEE', '#ff9f43'],
                    total: null
                };
            } else {
                return {
                    series: [
                        {
                            name: 'APP',
                            data: []
                        },
                        {
                            name: 'TELEGRAM',
                            data: []
                        },
                        {
                            name: 'USSD',
                            data: []
                        }
                    ],
                    xaxis: [],
                    completed: false,
                    chartLineColors: ['#00e296', '#2AABEE', '#ff9f43'],
                    total: null
                };
            }
        },
        subscriberMatching() {
            if (parseInt(MERCHANT_ID) === 1) {
                return {
                    series: [
                        {
                            name: 'APP',
                            data: []
                        },
                        {
                            name: 'TELEGRAM',
                            data: []
                        },
                        {
                            name: 'USSD',
                            data: []
                        }
                    ],
                    xaxis: [],
                    completed: false,
                    chartLineColors: ['#00e296', '#2AABEE', '#ff9f43'],
                    total: null
                };
            } else {
                return {
                    series: [
                        {
                            name: 'APP',
                            data: []
                        },
                        {
                            name: 'TELEGRAM',
                            data: []
                        },
                        {
                            name: 'USSD',
                            data: []
                        }
                    ],
                    xaxis: [],
                    completed: false,
                    chartLineColors: ['#00e296', '#2AABEE', '#ff9f43'],
                    total: null
                };
            }
        }
    },

    async mounted() {
        await this.fixStatisticsDates();
    },

    methods: {
        checkLocales,
        formatNumber,
        statisticsFormatDate,

        async fixStatisticsDates() {
            const date = new Date();
            const year = date.getUTCFullYear();
            // const beforeMonth = String(date.getMonth()).padStart(2, '0');
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
            // const day = String(date.getDate()).padStart(2, '0');

            const date_start = new Date();
            const date_end = new Date();
            date_start.setDate(date_end.getDate() - 30);

            this.date_start = this.statisticsFormatDate(date_start);
            this.date_end = this.statisticsFormatDate(date_end);
            this.first_day = `${year}-${currentMonth}-01`;

            // MATCHING DATES
            this.matchingDateStart = this.statisticsFormatDate(date_start);
            this.matchingDateEnd = this.statisticsFormatDate(date_end);
            this.matchingFirstDay = `${year}-${currentMonth}-01`;

            // this.datesArr = this.getAllDatesInRange(date_start, date_end);

            // await this.allRequests();
        },

        // SUBSCRIBERS DAILY
        async getSubscribers() {
            this.subscriber.completed = false;
            this.subscriber.xaxis.length = 0;
            this.uniqueDatesSet = new Set();
            this.subscriber.series[0].data.length = 0;
            this.subscriber.series[1].data.length = 0;
            if (parseInt(MERCHANT_ID) === 1) {
                this.subscriber.series[2].data.length = 0;
            }
            // this.subscriber.series[2].data.length = 0;
            try {
                const { data } = await api.homeUsersStatistics.fetchDailyApp({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.first_day,
                    date_end: this.date_end
                });

                // CREATE A NEW ARRAY WITHOUT DUPLICATED DATES FROM RESPONSE
                for (const item of data.data) {
                    if (item.app === 'app') {
                        this.subscriber.series[0].data.push(parseInt(item['verified_count']));
                    } else if (item.app === 'telegram') {
                        this.subscriber.series[1].data.push(parseInt(item['verified_count']));
                    } else {
                        this.subscriber.series[2].data.push(parseInt(item['verified_count']));
                    }
                    if (!this.uniqueDatesSet.has(item.date)) {
                        this.uniqueDatesSet.add(item.date);
                        this.subscriber.xaxis.push(item.date);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },

        // SUBSCRIBERS DAILY MATCHING
        async getSubscribersMatching() {
            this.subscriberMatching.completed = false;
            this.subscriberMatching.xaxis.length = 0;
            this.uniqueDatesSet = new Set();
            this.subscriberMatching.series[0].data.length = 0;
            this.subscriberMatching.series[1].data.length = 0;
            if (parseInt(MERCHANT_ID) === 1) {
                this.subscriberMatching.series[2].data.length = 0;
            }
            // this.subscriber.series[2].data.length = 0;
            try {
                const { data } = await api.homeUsersStatistics.fetchDailyApp({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.matchingFirstDay,
                    date_end: this.matchingDateEnd
                });

                // CREATE A NEW ARRAY WITHOUT DUPLICATED DATES FROM RESPONSE
                for (const item of data.data) {
                    if (item.app === 'app') {
                        this.subscriberMatching.series[0].data.push(
                            parseInt(item['verified_count'])
                        );
                    } else if (item.app === 'telegram') {
                        this.subscriberMatching.series[1].data.push(
                            parseInt(item['verified_count'])
                        );
                    } else {
                        this.subscriberMatching.series[2].data.push(
                            parseInt(item['verified_count'])
                        );
                    }
                    if (!this.uniqueDatesSet.has(item.date)) {
                        this.uniqueDatesSet.add(item.date);
                        this.subscriberMatching.xaxis.push(item.date);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },

        // SUBSCRIBERS TOTAL
        async getTotalSubscribers() {
            this.verificationPieChart.completed = false;
            this.activeUsersPieChart.completed = false;
            this.verificationPieChart.series.length = 0;
            this.verificationPieChart.labels.length = 0;
            this.activeUsersPieChart.series.length = 0;
            this.activeUsersPieChart.labels.length = 0;
            this.verificationPieChart.verified = null;
            this.verificationPieChart.unverified = null;
            this.activeUsersPieChart.active = null;
            this.activeUsersPieChart.inactive = null;
            try {
                const { data } = await api.homeUsersStatistics.fetchTotal({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                this.subscriber.total = data.data['verified_count'];
                this.verificationPieChart.series.push(
                    parseInt(data.data['verified_count']),
                    parseInt(data.data['unverified_count'])
                );
                this.verificationPieChart.labels.push('Verified', 'Unverified');
                this.verificationPieChart.verified = data.data['verified_count'];
                this.verificationPieChart.unverified = data.data['unverified_count'];
                this.activeUsersPieChart.series.push(
                    parseInt(data.data['active_count']),
                    parseInt(data.data['inactive_count'])
                );
                this.activeUsersPieChart.active = data.data['active_count'];
                this.activeUsersPieChart.inactive = data.data['inactive_count'];
                this.activeUsersPieChart.labels.push('Active', 'Inactive');
            } catch (e) {
                console.error(e);
            } finally {
                this.verificationPieChart.completed = true;
                this.activeUsersPieChart.completed = true;
                this.subscriber.completed = true;
            }
        },

        // SUBSCRIBERS TOTAL MATCHING
        async getTotalSubscribersMatching() {
            this.verificationPieChartMatching.completed = false;
            this.activeUsersPieChartMatching.completed = false;
            this.verificationPieChartMatching.series.length = 0;
            this.verificationPieChartMatching.labels.length = 0;
            this.activeUsersPieChartMatching.series.length = 0;
            this.activeUsersPieChartMatching.labels.length = 0;
            this.verificationPieChartMatching.verified = null;
            this.verificationPieChartMatching.unverified = null;
            this.activeUsersPieChartMatching.active = null;
            this.activeUsersPieChartMatching.inactive = null;
            try {
                const { data } = await api.homeUsersStatistics.fetchTotal({
                    merchant_id: MERCHANT_ID,
                    date_start: this.matchingFirstDay,
                    date_end: this.matchingDateEnd
                });
                this.subscriberMatching.total = data.data['verified_count'];
                this.verificationPieChartMatching.series.push(
                    parseInt(data.data['verified_count']),
                    parseInt(data.data['unverified_count'])
                );
                this.verificationPieChartMatching.labels.push('Verified', 'Unverified');
                this.verificationPieChartMatching.verified = data.data['verified_count'];
                this.verificationPieChartMatching.unverified = data.data['unverified_count'];
                this.activeUsersPieChartMatching.series.push(
                    parseInt(data.data['active_count']),
                    parseInt(data.data['inactive_count'])
                );
                this.activeUsersPieChartMatching.active = data.data['active_count'];
                this.activeUsersPieChartMatching.inactive = data.data['inactive_count'];
                this.activeUsersPieChartMatching.labels.push('Active', 'Inactive');
            } catch (e) {
                console.error(e);
            } finally {
                this.verificationPieChartMatching.completed = true;
                this.activeUsersPieChartMatching.completed = true;
                this.subscriberMatching.completed = true;
            }
        },

        // GIFTS DAILY AND TOTAL
        // PROFIT EXPENSES (DAILY AND TOTAL)
        async getGifts() {
            this.gifts.completed = false;
            this.profitExpenses.completed = false;
            this.gifts.series[0].data.length = 0;
            this.gifts.xaxis.length = 0;
            this.profitExpenses.series[0].data.length = 0;
            this.profitExpenses.xaxis.length = 0;
            this.profitExpenses.total = null;
            this.gifts.total = null;
            try {
                const totalData = await api.homePackagesStatistics.fetchTotal({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                const { data } = await api.homePackagesStatistics.fetchDaily({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                this.profitExpenses.total = totalData.data.data.sum;
                this.gifts.total = totalData.data.data.count;
                data.data.forEach((item) => {
                    this.gifts.series[0].data.push(parseInt(item.count));
                    // this.gifts.total += parseInt(item.count);
                    this.gifts.xaxis.push(item.date);

                    this.profitExpenses.series[0].data.push(parseInt(item.sum));
                    // this.profitExpenses.total += parseInt(item.sum);
                    this.profitExpenses.xaxis.push(item.date);
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.gifts.completed = true;
                this.profitExpenses.completed = true;
            }
        },

        // GIFTS DAILY AND TOTAL MATCHING
        // PROFIT EXPENSES (DAILY AND TOTAL) MATCHING
        async getGiftsMatching() {
            this.giftsMatching.completed = false;
            this.profitExpensesMatching.completed = false;
            this.giftsMatching.series[0].data.length = 0;
            this.giftsMatching.xaxis.length = 0;
            this.profitExpensesMatching.series[0].data.length = 0;
            this.profitExpensesMatching.xaxis.length = 0;
            this.profitExpensesMatching.total = null;
            this.giftsMatching.total = null;
            try {
                const totalData = await api.homePackagesStatistics.fetchTotal({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.matchingFirstDay,
                    date_end: this.matchingDateEnd
                });
                const { data } = await api.homePackagesStatistics.fetchDaily({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                this.profitExpensesMatching.total = totalData.data.data.sum;
                this.giftsMatching.total = totalData.data.data.count;
                data.data.forEach((item) => {
                    this.giftsMatching.series[0].data.push(parseInt(item.count));
                    // this.gifts.total += parseInt(item.count);
                    this.giftsMatching.xaxis.push(item.date);

                    this.profitExpensesMatching.series[0].data.push(parseInt(item.sum));
                    // this.profitExpenses.total += parseInt(item.sum);
                    this.profitExpensesMatching.xaxis.push(item.date);
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.giftsMatching.completed = true;
                this.profitExpensesMatching.completed = true;
            }
        },

        // INVOICE DAILY AND TOTAL
        // PROFIT TOTAL (DAILY AND TOTAL)
        async getInvoices() {
            this.invoice.completed = false;
            this.profitTotal.completed = false;
            this.invoice.series[0].data.length = 0;
            this.invoice.xaxis.length = 0;
            this.profitTotal.series[0].data.length = 0;
            this.profitTotal.xaxis.length = 0;
            try {
                // homeChargesStatistics.fetchTotal
                const totalData = await api.homeChargesStatistics.fetchTotal({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                const { data } = await api.homeChargesStatistics.fetchDaily({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                this.profitTotal.total = totalData.data.data.sum;
                this.invoice.total = totalData.data.data.count;
                data.data.forEach((item) => {
                    this.invoice.series[0].data.push(parseInt(item.count));
                    // this.invoice.total += parseInt(item.count);
                    this.invoice.xaxis.push(item.date);

                    this.profitTotal.series[0].data.push(parseInt(item.sum));
                    // this.profitTotal.total += parseInt(item.sum);
                    this.profitTotal.xaxis.push(item.date);
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.invoice.completed = true;
                this.profitTotal.completed = true;
            }
        },

        // INVOICE DAILY AND TOTAL MATCHING
        // PROFIT TOTAL (DAILY AND TOTAL) MATCHING
        async getInvoicesMatching() {
            this.invoiceMatching.completed = false;
            this.profitTotalMatching.completed = false;
            this.invoiceMatching.series[0].data.length = 0;
            this.invoiceMatching.xaxis.length = 0;
            this.profitTotalMatching.series[0].data.length = 0;
            this.profitTotalMatching.xaxis.length = 0;
            try {
                // homeChargesStatistics.fetchTotal
                const totalData = await api.homeChargesStatistics.fetchTotal({
                    merchant_id: parseInt(MERCHANT_ID),
                    date_start: this.matchingFirstDay,
                    date_end: this.matchingDateEnd
                });
                const { data } = await api.homeChargesStatistics.fetchDaily({
                    merchant_id: MERCHANT_ID,
                    date_start: this.matchingFirstDay,
                    date_end: this.matchingDateEnd
                });
                this.profitTotalMatching.total = totalData.data.data.sum;
                this.invoiceMatching.total = totalData.data.data.count;
                data.data.forEach((item) => {
                    this.invoiceMatching.series[0].data.push(parseInt(item.count));
                    // this.invoice.total += parseInt(item.count);
                    this.invoiceMatching.xaxis.push(item.date);

                    this.profitTotalMatching.series[0].data.push(parseInt(item.sum));
                    // this.profitTotal.total += parseInt(item.sum);
                    this.profitTotalMatching.xaxis.push(item.date);
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.invoiceMatching.completed = true;
                this.profitTotalMatching.completed = true;
            }
        },

        // PROFIT REVENUE
        async getProfitRevenue() {
            this.profitRevenue.completed = false;
            this.profitRevenue.series[0].data.length = 0;
            this.profitRevenue.xaxis.length = 0;
            try {
                const { data } = await api.homeInvoiceStatistics.fetchRevenue({
                    merchant_id: MERCHANT_ID,
                    date_start: this.first_day,
                    date_end: this.date_end
                });
                data.data.dates.forEach((item) => {
                    this.profitRevenue.series[0].data.push(parseInt(item.sum));
                    this.profitRevenue.xaxis.push(item.date);
                });
                this.profitRevenue.total = data.data.total.sum;
            } catch (e) {
                console.error(e);
            } finally {
                this.profitRevenue.completed = true;
            }
        },

        // PROFIT REVENUE MATCHING
        async getProfitRevenueMatching() {
            this.profitRevenueMatching.completed = false;
            this.profitRevenueMatching.series[0].data.length = 0;
            this.profitRevenueMatching.xaxis.length = 0;
            try {
                const { data } = await api.homeInvoiceStatistics.fetchRevenue({
                    merchant_id: MERCHANT_ID,
                    date_start: this.matchingFirstDay,
                    date_end: this.matchingDateEnd
                });
                data.data.dates.forEach((item) => {
                    this.profitRevenueMatching.series[0].data.push(parseInt(item.sum));
                    this.profitRevenueMatching.xaxis.push(item.date);
                });
                this.profitRevenueMatching.total = data.data.total.sum;
            } catch (e) {
                console.error(e);
            } finally {
                this.profitRevenueMatching.completed = true;
            }
        },

        async filterRequests() {
            this.showNoDataFrom = false;
            await this.getSubscribers();
            await this.getTotalSubscribers();

            // GIFTS
            await this.getGifts();

            // INVOICE
            await this.getInvoices();

            // PROFIT REVENUE
            await this.getProfitRevenue();
        },

        async filterRequestsMatching() {
            this.showNoDataTo = false;
            await this.getSubscribersMatching();
            await this.getTotalSubscribersMatching();

            // GIFTS
            await this.getGiftsMatching();

            // INVOICE
            await this.getInvoicesMatching();

            // PROFIT REVENUE
            await this.getProfitRevenueMatching();
        }
    }
};
</script>

<template>
    <div class="home">
        <div class="row align-items-end mb-2">
            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                    <flat-pickr
                        v-model="first_day"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="start_date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                    <flat-pickr
                        v-model="date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="end_date"
                    />
                </div>
            </div>
            <div class="col-12 col-md-2 px-2">
                <b-button @click="filterRequests" class="w-100 mt-1 mt-md-0" variant="primary">
                    <feather-icon icon="SearchIcon" />
                </b-button>
            </div>
        </div>
        <!--        <h3 class="px-1">-->
        <!--            {{-->
        <!--                $t('titles.from_date_to_date', {-->
        <!--                    from: first_day,-->
        <!--                    to: date_end-->
        <!--                })-->
        <!--            }}-->
        <!--        </h3>-->
        <div class="divider from-matching__divider">
            <div v-if="showNoDataFrom">
                <h3>{{ $t('no_data') }}</h3>
            </div>
            <div v-else class="cards__grid">
                <!--      SUBSCRIBERS      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!subscriber.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="UsersIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="titles.users"
                        :total="subscriber.total"
                        :chart-line-colors="subscriber.chartLineColors"
                        :series="subscriber.series"
                        :dynamic-xaxis="subscriber.xaxis"
                        :loaded="subscriber.completed"
                    />
                </div>

                <!--      GIFTS      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!gifts.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="GiftIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="titles.gifts"
                        :total="gifts.total"
                        :chart-line-colors="gifts.chartLineColors"
                        :series="gifts.series"
                        :dynamic-xaxis="gifts.xaxis"
                        :loaded="gifts.completed"
                    />
                </div>

                <!--      INVOICE      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!invoice.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="FileTextIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="Invoices"
                        :total="invoice.total"
                        :chart-line-colors="invoice.chartLineColors"
                        :series="invoice.series"
                        :dynamic-xaxis="invoice.xaxis"
                        :loaded="invoice.completed"
                    />
                </div>

                <!--      SUBSCRIBERS VERIFICATIONS      -->
                <div class="card d-flex justify-content-between align-items-center">
                    <h3 v-if="verificationPieChart.completed" class="subscribers__title">
                        Subscribers
                    </h3>
                    <div
                        v-if="verificationPieChart.completed"
                        class="w-100 d-flex align-items-center justify-content-around"
                    >
                        <p class="mb-0">
                            Verified: {{ formatNumber(verificationPieChart.verified) }}
                        </p>
                        <p class="mb-0">
                            Unverified: {{ formatNumber(verificationPieChart.unverified) }}
                        </p>
                    </div>
                    <progress-linear v-if="!verificationPieChart.completed" />
                    <apexchart
                        v-else
                        width="220"
                        :options="verificationPieChart"
                        :series="verificationPieChart.series"
                    />
                </div>

                <!--      PROFIT TOTAL      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!profitTotal.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="GiftIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="Profit Total"
                        :total="profitTotal.total"
                        :chart-line-colors="profitTotal.chartLineColors"
                        :series="profitTotal.series"
                        :dynamic-xaxis="profitTotal.xaxis"
                        :loaded="profitTotal.completed"
                    />
                </div>

                <!--      PROFIT EXPENSES      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!profitExpenses.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="GiftIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="Profit Expenses"
                        :total="profitExpenses.total"
                        :chart-line-colors="profitExpenses.chartLineColors"
                        :series="profitExpenses.series"
                        :dynamic-xaxis="profitExpenses.xaxis"
                        :loaded="profitExpenses.completed"
                    />
                </div>

                <!--      PROFIT REVENUE      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!profitRevenue.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="GiftIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="Profit Revenue"
                        :total="profitRevenue.total"
                        :chart-line-colors="profitRevenue.chartLineColors"
                        :series="profitRevenue.series"
                        :dynamic-xaxis="profitRevenue.xaxis"
                        :loaded="profitRevenue.completed"
                    />
                </div>

                <!--      SUBSCRIBERS ACTIVE RATE      -->
                <div class="card d-flex justify-content-between align-items-center">
                    <h3 v-if="activeUsersPieChart.completed" class="subscribers__title">
                        Subscribers
                    </h3>
                    <div
                        v-if="activeUsersPieChart.completed"
                        class="w-100 d-flex align-items-center justify-content-around"
                    >
                        <p class="mb-0">Active: {{ formatNumber(activeUsersPieChart.active) }}</p>
                        <p class="mb-0">
                            Inactive: {{ formatNumber(activeUsersPieChart.inactive) }}
                        </p>
                    </div>
                    <progress-linear v-if="!activeUsersPieChart.completed" />
                    <apexchart
                        v-else
                        width="220"
                        :options="activeUsersPieChart"
                        :series="activeUsersPieChart.series"
                    />
                </div>
            </div>
        </div>

        <div class="row align-items-end mb-2">
            <div class="col-12 col-md-10 d-flex flex-wrap align-items-center">
                <div class="col-12 col-sm-6">
                    <label for="start_date">{{ $t('titles.date_start_at') }}</label>
                    <flat-pickr
                        v-model="matchingFirstDay"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="start_date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-1 mt-sm-0">
                    <label for="end_date">{{ $t('titles.date_end_at') }}</label>
                    <flat-pickr
                        v-model="matchingDateEnd"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                        id="end_date"
                    />
                </div>
            </div>
            <div class="col-12 col-md-2 px-2">
                <b-button
                    @click="filterRequestsMatching"
                    class="w-100 mt-1 mt-md-0"
                    variant="primary"
                >
                    <feather-icon icon="SearchIcon" />
                </b-button>
            </div>
        </div>
        <!--        <h3 class="px-1">-->
        <!--            {{-->
        <!--                $t('titles.from_date_to_date', {-->
        <!--                    from: matchingFirstDay,-->
        <!--                    to: matchingDateEnd-->
        <!--                })-->
        <!--            }}-->
        <!--        </h3>-->
        <div class="divider">
            <div v-if="showNoDataTo">
                <h3>{{ $t('no_data') }}</h3>
            </div>
            <div v-else class="cards__grid">
                <!--      SUBSCRIBERS      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!subscriberMatching.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="UsersIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="titles.users"
                        :total="subscriberMatching.total"
                        :chart-line-colors="subscriberMatching.chartLineColors"
                        :series="subscriberMatching.series"
                        :dynamic-xaxis="subscriberMatching.xaxis"
                        :loaded="subscriberMatching.completed"
                    />
                </div>

                <!--      GIFTS      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!giftsMatching.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="GiftIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="titles.gifts"
                        :total="giftsMatching.total"
                        :chart-line-colors="giftsMatching.chartLineColors"
                        :series="giftsMatching.series"
                        :dynamic-xaxis="giftsMatching.xaxis"
                        :loaded="giftsMatching.completed"
                    />
                </div>

                <!--      INVOICE      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!invoiceMatching.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="FileTextIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="Invoices"
                        :total="invoiceMatching.total"
                        :chart-line-colors="invoiceMatching.chartLineColors"
                        :series="invoiceMatching.series"
                        :dynamic-xaxis="invoiceMatching.xaxis"
                        :loaded="invoiceMatching.completed"
                    />
                </div>

                <!--      SUBSCRIBERS VERIFICATIONS      -->
                <div class="card d-flex justify-content-between align-items-center">
                    <h3 v-if="verificationPieChartMatching.completed" class="subscribers__title">
                        Subscribers
                    </h3>
                    <div
                        v-if="verificationPieChartMatching.completed"
                        class="w-100 d-flex align-items-center justify-content-around"
                    >
                        <p class="mb-0">
                            Verified: {{ formatNumber(verificationPieChartMatching.verified) }}
                        </p>
                        <p class="mb-0">
                            Unverified: {{ formatNumber(verificationPieChartMatching.unverified) }}
                        </p>
                    </div>
                    <progress-linear v-if="!verificationPieChartMatching.completed" />
                    <apexchart
                        v-else
                        width="220"
                        :options="verificationPieChartMatching"
                        :series="verificationPieChartMatching.series"
                    />
                </div>

                <!--      PROFIT TOTAL      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!profitTotalMatching.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="GiftIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="Profit Total"
                        :total="profitTotalMatching.total"
                        :chart-line-colors="profitTotalMatching.chartLineColors"
                        :series="profitTotalMatching.series"
                        :dynamic-xaxis="profitTotalMatching.xaxis"
                        :loaded="profitTotalMatching.completed"
                    />
                </div>

                <!--      PROFIT EXPENSES      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!profitExpensesMatching.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="GiftIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="Profit Expenses"
                        :total="profitExpensesMatching.total"
                        :chart-line-colors="profitExpensesMatching.chartLineColors"
                        :series="profitExpensesMatching.series"
                        :dynamic-xaxis="profitExpensesMatching.xaxis"
                        :loaded="profitExpensesMatching.completed"
                    />
                </div>

                <!--      PROFIT REVENUE      -->
                <div class="card d-flex flex-column">
                    <progress-linear v-if="!profitRevenueMatching.completed" />
                    <AppHomeCardWithChart
                        v-else
                        icon="GiftIcon"
                        icon-size="24"
                        icon-color="light-primary"
                        total-text="Profit Revenue"
                        :total="profitRevenueMatching.total"
                        :chart-line-colors="profitRevenueMatching.chartLineColors"
                        :series="profitRevenueMatching.series"
                        :dynamic-xaxis="profitRevenueMatching.xaxis"
                        :loaded="profitRevenueMatching.completed"
                    />
                </div>

                <!--      SUBSCRIBERS ACTIVE RATE      -->
                <div class="card d-flex justify-content-between align-items-center">
                    <h3 v-if="activeUsersPieChartMatching.completed" class="subscribers__title">
                        Subscribers
                    </h3>
                    <div
                        v-if="activeUsersPieChartMatching.completed"
                        class="w-100 d-flex align-items-center justify-content-around"
                    >
                        <p class="mb-0">
                            Active: {{ formatNumber(activeUsersPieChartMatching.active) }}
                        </p>
                        <p class="mb-0">
                            Inactive: {{ formatNumber(activeUsersPieChartMatching.inactive) }}
                        </p>
                    </div>
                    <progress-linear v-if="!activeUsersPieChartMatching.completed" />
                    <apexchart
                        v-else
                        width="220"
                        :options="activeUsersPieChartMatching"
                        :series="activeUsersPieChartMatching.series"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.from-matching__divider {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 2px dashed #c4c4c4;
}

.cards__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 0;
}

@media only screen and (max-width: 1550px) {
    .cards__grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 1300px) {
    .cards__grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 700px) {
    .cards__grid {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}
</style>
